<template lang='pug'>
extends ../templates/PageBase.pug
block content
  .container-fluid.container-fixed-lg
    .row.no-margin
      .col-md-6.no-padding.b-r.b-grey
        .card.card-default
          .card-header
            .card-title
              | Input
          .card-body
            code-editor(mode='json' name='input' ref='editor' height='724px')
      .col-md-6.no-padding
        .card.card-default(:class='{"card-maximized": isMaximize}')
          .card-header
            .card-title
              | Output
          .card-body
            div(role='form')
              .form-group.form-group-default.input-group
                .form-input-group
                  label JSON Path
                  input.form-control(v-model='jPath' @keyup.enter='debounceOutputCode')
                .input-group-append
                  span.input-group-text(v-model='jPath')
                    i.pg-refresh(v-if='!processing')
                    .progress-circle-indeterminate.activity(v-if='processing')
              .form-group
                code-editor(mode='json' name='result' ref='editorResult' :read-only='true')

  .container-fluid.container-fixed-lg.bg-white
    .card.card-transparent(ref='howtoCard')
      .card-header
        .card-title
          | Howto use JSON Path
        .card-controls
            ul
              li
                a.card-collapse(href='#', data-toggle='collapse')
                  i.pg-arrow_maximize
      .card-body
        .row
          .col-lg-6
            .card.card-default
              .card-header
                .card-title
                  | Example JSON
              .card-body
                <!--div.example-editor(ref='editorExample')-->
                code-editor(mode='json' name='example' ref='editorExample' :read-only='true' height='500px')
          .col-lg-6
            .card.card-default
              .card-header
                .card-title
                  | Filter
              .card-body
                .table-responsive
                  #detailedTable_wrapper.dataTables_wrapper.no-footer
                    table#detailedTable.table.table-hover.table-detailed.dataTable.no-footer(role='grid')
                      thead
                        tr(role='row')
                          th.sorting_disabled(style='width: 150px') JSON Path
                          th.sorting_disabled(style='width: 50%;') Description
                      tbody
                        tr.odd(role='row' v-for='item in helpTable')
                          td.v-align-middle.semi-bold
                            code {{ item.path }}
                          td.v-align-middle {{ item.desc }}
                p.m-t-30
                  | Need more detailed information?
                  | See&nbsp;
                  a(href="https://goessner.net/articles/JsonPath/index.html#e2" target='_blank') here&nbsp;
                  | for more information
</template>

<script>
import ace from 'ace-builds/src-noconflict/ace'
// import themePreview from 'ace-builds/src-noconflict/theme-chrome'
import jsonExample from '../assets/jsonpath-example'
import '../assets/plugins/ion-slider/js/ion.rangeSlider.min'

import JSONPath from 'JSONPath'
import CodeEditor from '../components/CodeEditor'

const CDN = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.1/src-min-noconflict'
ace.config.set('basePath', CDN)
ace.config.set('modePath', CDN)
ace.config.set('themePath', CDN)
ace.config.set('workerPath', CDN)

const helpTable = [
  {path: '$', desc: 'All members of JSON structure.'},
  {path: '$..*', desc: 'All members of JSON structure.'},
  {path: '$.store.book[*].author', desc: 'the authors of all books in the store'},
  {path: '$..author', desc: 'All authors'},
  {path: '$.store.*', desc: 'All things in store, which are some books and a red bicycle.'},
  {path: '$.store..price', desc: 'The price of everything in the store.'},
  {path: '$..book[2]', desc: 'The third book'},
  {path: '$..book[(@.length-1)]', desc: 'The last book in order.'},
  {path: '$..book[-1:]', desc: 'The last book in order.'},
  {path: '$..book[0,1]', desc: 'The first two books'},
  {path: '$..book[:2]', desc: 'The first two books'},
  {path: '$..book[::2]', desc: 'Every second books'},
  {path: '$..book[?(@.isbn)]', desc: 'Filter all books with isbn number'},
  {path: '$..book[?(@.isbn=="abc213")]', desc: 'Filter all books with isbn number is "abc213"'},
  {path: '$..book[?(@.price<10)]', desc: 'Filter all books cheapier than 10'},
  {path: '$.store.book[?(@.rating.value>5)]', desc: 'Filter all books with nested ratings.rating is greater then 5'}
]

// what is json-path
// why do i need that
//

export default {
  name: 'JSONView',
  components: {CodeEditor},
  mounted () {
    const self = this
    this.outputCodeTimeOut = null

    this.$refs.editorExample.setContent(JSON.stringify(jsonExample, null, 2))
    window.$(this.$refs.howtoCard).card('collapse')


    this.$on('editorContentChanged', function (event) {
      if (event.name === 'input') {
        self.inputCode = event.editor.getValue()
      }
    })
  },
  data () {
    return {
      isMaximize: false,
      inputCode: null,
      outputCode: null,
      indentation: 2,
      helpTable: helpTable,
      jPath: '$',
      processing: false
    }
  },
  methods: {
    debounceOutputCode() {
      const self = this
      const path = self.jPath
      const code = self.inputCode
      self.processing = true

      if (path && code) {
        clearTimeout(self.outputCodeTimeOut)

        self.outputCodeTimeOut = window.setTimeout(function () {
          const result = JSONPath({json: JSON.parse(code), path: path})
          self.$refs.editorResult.setContent(JSON.stringify(result, null, self.indentationInt))
          self.processing = false
        }, 500)
      } else {
        self.processing = false
      }
    }
  },
  watch: {
    inputCode() {
      this.debounceOutputCode()
    },
    indentation() {
      this.debounceOutputCode()
      this.$ga.event({eventCategory: 'editor', eventAction: 'change', eventLabel: 'indentation'})
    }
  },
  computed: {
    indentationInt() {
      return parseInt(this.indentation) || 2
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/plugins/ion-slider/css/ion.rangeSlider.css";
@import "../assets/plugins/ion-slider/css/ion.rangeSlider.skinFlat.css";

.editor, .example-editor {
  height: 500px;
  width: 100%;

  &.input {
    height: 529px;
  }

}

.activity {
  width: 22px;
  height: 22px;
}
</style>
